
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import { BvTableCtxObject } from "bootstrap-vue"
  import { postingColumns } from '@/view_scripts/table_columns/posting_columns'
  import { apiDataMorpher } from "@/utils/apiDataHelper"
  import { fullName } from '@/utils/dataExtractors'

  export default defineComponent({
    components: {},
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => root.$store.state.postings.pagination.totalItems)
      const sample = computed(() => root.$store.state.postings.data?.[0])
      const getItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'include': 'household',
          'sort': '-created_at'
        }
        await root.$store.dispatch('postings/load', queryParams)
        return root.$store.state.postings.data
      }

      const columns = ref(postingColumns)

      const titleActions = ref([
        {
          title: 'Dokument',
          icon: 'plus',
          tooltip: 'Dokument',
          onClick: () => alert('boubou'),
        },
      ])

      return {
        getItems,
        totalItems,
        columns,
        titleActions,
        sample
      }
    }
  })
